<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import Document from '../../../components/api/document-callback'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Document
  },
  data() {
    return {
      title: this.$t('잔액확인 콜백'),
      apiUrl: this.$store.getters['auth/user'].seamlessCallbackUrl.balance,
      response: '',
      // username, vendorKey, gameKey, ipAddress, lobbyKey, platform, requestKey
      body: {
        apiKey: {type: 'text', value: this.$store.getters['auth/user'].apiKey, desc: '부여받은 apiKey', required: true, readonly: true},
        params: {type: 'json', value: JSON.stringify({siteUsername: 'user1', username: 'tp1_user1'}), desc: '값을 사이트에 맞게 수정하세요.', required: true}
      }
    };
  },
  mounted() {
    this.response = '----------------------------------------------------------------------------\n' +
        '[사이트 콜백 수신값]\n' +
        '----------------------------------------------------------------------------\n' +
        '{\n' +
        `  apiKey: "${this.$store.getters['auth/user'].apiKey}", // API으로부터 부여 받은 apiKey\n` +
        '  params: {\n' +
        `    siteUsername: "user1"\n` +
        `    username: "tp1_user1"\n` +
        '  },\n' +
        '  requestAt: \'2022-09-13T00:40:10.923Z\' // API의 요청 시간\n' +
        '}\n\n' +
        '----------------------------------------------------------------------------\n' +
        '[성공 응답값]\n' +
        '----------------------------------------------------------------------------\n' +
        '  {\n' +
        '    code:0,\n' +
        '    data: {\n' +
        '      balance:0,\n' +
        '      currency:"KRW"\n' +
        '    }\n' +
        '  }\n' +
        '----------------------------------------------------------------------------\n' +
        '[실패 응답값]\n' +
        '----------------------------------------------------------------------------\n' +
        '  {\n' +
        '    code: -1, // 실패코드로서 0이 아닌 아무 값이나 입력하시면 됩니다.\n' +
        '    msg: "NOT_VALID" // 디버깅을 위한 메세지\n' +
        '  }'
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title"><span class="badge bg-success">콜백 URL</span>
              {{ apiUrl }}
            </div>
            <div class="card-title-desc">사이트 회원의 잔액 확인 요청을 합니다.</div>
            <hr/>
            <Document :data="body" :apiUrl="apiUrl" :response="response" :require-vendors="false" :require-ip-address="false" :require-request-key="false" :requireSample="false" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
